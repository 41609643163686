interface ServiceTabsProps {
  signedIn: boolean
  currentTab: string
  myAppletsUrl: string
  discoverAppletsUrl: string
  myAppletsContainer: string
  discoverAppletsContainer: string
}

const ServiceTabs = ({ signedIn, currentTab, myAppletsUrl, discoverAppletsUrl, myAppletsContainer, discoverAppletsContainer }: ServiceTabsProps) => {
  // Signed-out users load Applets in backend and it's cached. It doesn't have my Applets.
  if (signedIn) {
    const loadApplets = (url:string, containerClass:string) => {
      fetch(url)
        .then(response => response.text())
        .then(html => {
          const appletContainer = document.querySelector(`section[data-tab] ${containerClass}`)
          appletContainer.classList.remove("loading")
          appletContainer.innerHTML = html
          window.App.Scripts.VanillaFittty(appletContainer)
        })
    }

    if (currentTab === "my_applets") {
      loadApplets(myAppletsUrl, myAppletsContainer)
      loadApplets(discoverAppletsUrl, discoverAppletsContainer)
    } else {
      loadApplets(discoverAppletsUrl, discoverAppletsContainer)
      loadApplets(myAppletsUrl, myAppletsContainer)
    }
  }

  document.querySelectorAll(".tab-content.filters a").forEach(tab => {
    tab.addEventListener("click", (event) => {
      if (!event) return

      event.preventDefault()
      const tab = event.target.closest("a")

      if (tab.classList.contains("active")) return

      document.querySelectorAll(".tab-content.filters a").forEach(tab => tab.classList.remove("active"))
      tab.classList.add("active")

      const tabContent = document.querySelector(`section[data-tab=${tab.dataset.target}]`)
      document.querySelectorAll("section[data-tab]").forEach(content => content.classList.add("hide"))
      tabContent?.classList.remove("hide")

      window.App.Scripts.VanillaFittty(tabContent)

      window.history.replaceState({ url: tab.href }, "", tab.href)
    })
  })
}

export default ServiceTabs
