import InitializeFacebook from "app/scripts/initialize_facebook"
import SetDatadogRumContext from "app/scripts/dd_rum_context"
import LazyBGImageObserver from "app/scripts/lazy_bg_img_observer"
import ServiceTabs from "app/scripts/service_tabs"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import { setAppReady } from "shared/lib/utils"

Object.assign(window.App.Scripts, {
  InitializeFacebook,
  LazyBGImageObserver,
  ServiceTabs,
  SetDatadogRumContext,
  SetupUnifiedHeaderDropdown,
  SetupUnifiedMobileMenu,
})

setAppReady()
