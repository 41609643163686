export default function initializeFacebook(appId) {
  window.fbAsyncInit = function () {
    FB.init({
      appId,
      version: "v2.9",
      cookie: true,
    })
  }

  window.App.loadScriptAsync("//connect.facebook.net/en_US/sdk.js", "facebook-jssdk", { crossorigin: "anonymous" }, 1)
    .catch(() => {throw new Error("Failed to load FB SDK.")})
}
